import React from 'react'
import classNames from 'classnames'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import Hero from '../components/Hero'

const ns = `page`

const WellnessPage = ({ data }) => {
  const { markdownRemark } = data
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  return (
    <Layout>
      <div className={rootClassnames}>
        <Hero
          hero={{
            urlMobile:
              markdownRemark.frontmatter.hero.hero_image_mobile.publicURL,
            urlDesktop:
              markdownRemark.frontmatter.hero.hero_image_desktop.publicURL,
          }}
        />
        <div className={`${ns}__container`}>
          <h1 className="page--title">{markdownRemark.frontmatter.title}</h1>
          <div className={`${ns}__content-container`}>
            {markdownRemark.frontmatter.sections.map(section => (
              <div className={`${ns}__sections`}>
                <h2 className="sub-title">{section.title}</h2>
                <img
                  className="img-fluid"
                  src={section.image.publicURL}
                  alt=""
                />
                <p>{section.content}</p>
              </div>
            ))}
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-12 col-md-11">
                  <h3
                    className="statment--text"
                    dangerouslySetInnerHTML={{
                      __html: markdownRemark.frontmatter.statement,
                    }}
                  />
                </div>
              </div>
              <div className="row justify-content-center">
                {markdownRemark.frontmatter.upcoming_events.show_section && (
                  <Link className="button" to="/calendar">
                    {markdownRemark.frontmatter.upcoming_events.link_label}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default WellnessPage

export const wellnessPageQuery = graphql`
  query WellnessPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        sections {
          title
          content
          image {
            publicURL
          }
        }
        hero {
          hero_image_desktop {
            publicURL
          }
          hero_image_mobile {
            publicURL
          }
        }
        statement
        title
        upcoming_events {
          link_label
          show_section
        }
      }
    }
  }
`
